:focus {
  outline-color: transparent;
  outline-style: none;
}

a {
  text-decoration: none;
}


.clr {
  &:after {
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;
   }
}

.bold-text {
  font-weight: 600;
}

.trucate {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pull-left {
  float: left!important;
}

.pull-right {
  float: right!important;
}

.no-padding {
  padding: 0!important;
}

.workspace-icon {
  font-family: "eleoticons" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  &.menu:before {
    content: "s"
  }

  &.cloud:before {
    content: "\42"
  }

  &.users:before {
    content: "\79"
  }

  &.activites:before {
   content: "\59";
  }

  &.cross:before {
   content: "\6b";
  }

  &.block:before {
   content: "4";
  }

  &.left-menu-pane-open:before {
    content: "\5c";
  }

  &.left-menu-pane-close:before {
    content: "\e000";
  }

  &.arrow-open:before{
    content: "c";
  }

  &.arrow-close:before{
    content: "b";
  }
}


.row {
  @extend .clr;
  padding-bottom: 15px;
}

.col {
  float: left;
}

.col + .col {
  padding-left:25px;
}

.three-four,
.three-fourths {
  width: 75%;
}

.one-three,
.third {
  width: 33.33%;
}

.two-three {
  width:66.67%;
}

.one-four,
.fourth {
  width: 25%;
}

.two-four,
.half  {
  width: 50%;
}

.full-width {
  width: 100%;
}

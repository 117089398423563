@import 'reset'; // Meyer reset
@import "../../node_modules/primeicons/primeicons.css";
@import "../../node_modules/primeng/resources/primeng.min.css";
@import "../../node_modules/font-awesome/css/font-awesome.css";
@import "./my-prime-ng-omega/my-prime-ng-omega-theme.scss";
@import "vars-mixins";
@import 'prime-theme'; // Customized prime-theme rules - not part of PrimeNG library
@import "variables";
@import "fonts";
@import "nucleo-font";
@import "text-element-mixins";
@import 'utilities';
@import 'grid-styles';
@import 'unique-grid-styles';
@import 'core-layout';
@import 'headers';
@import 'forms';
@import 'details';
@import 'overlay';
@import 'modal-styles';
@import 'resource-tree';
@import 'resource-modals';
@import 'confirm-cancel-modal';
@import 'prime-tooltip';
@import 'prime-menu-icons';
@import 'prime-activity-tabs';
@import "tooltip_styles";
@import 'eleot-icons';
@import 'diagnostic-view';
@import 'assign-notifications-component';
@import 'activity-menu-component.scss';
@import "./froala/froala";


// TODO: Write these into a separate SCSS file. They are overrides for Activities Assign Notifications.

ul > p-multiSelectItem:nth-child(odd) > li.ui-multiselect-item {
  background-color: #f8f8f8;
}
.pi-check:before {
  content: "\e909";
  position: relative;
  top: -3px;
}

@import './vars-mixins';
@mixin h1 () {
  h1 {
    font-size: $h1-font-size;
    color: $brand-purple;
    font-weight: bold;
    font-family: $open-sans;
  }
}
@mixin h2 () {
  h2 {
    font-size: $h2-font-size;
    color: $dark-text;
    font-weight: bold;
    font-family: $open-sans;
  }
}

@mixin h3 () {
  h3 {
    font-size: $h3-font-size;
    color: $dark-text;
    font-weight: bold;
    font-family: $open-sans;
  }
}

@mixin p () {
  p {
    color: $dark-text;
    font-family: $open-sans;
    line-height: 1.25;
  }
}

@mixin span () {
  span {
    color: $dark-text;
    font-family: $open-sans;
  }
}

@mixin label () {
  label {
    color: $dark-text;
    font-family: $open-sans;
  }
}

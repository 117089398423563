.ui-menu {
  padding: 0;

  .ui-menuitem {
    margin: 0;

    .ui-menuitem-link {
      color:$blue-text;

      &:hover{
        background-color: #E8EAF1;
        color:$blue-text;
      }
    }
  }
}

.ui-datatable-loading-content {
  display: none;
}

.ui-autocomplete-loader {
  display: none;
}

.ui-datatable-loading.ui-widget-overlay {
  display: none;
}

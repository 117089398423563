body.noscroll{
  overflow: hidden;
}

.scroll-section-head {
  font-weight: 600;
  color: $brand-purple;
  font-size: 20px;
  padding-bottom: 10px;
}

.scroll-section-wrap {
  &.at-bottom {
    padding-bottom:100vh;
  }
}

.scroll-child-wrap {
  position: relative;
  margin-bottom: 25px;
  @extend .clr;
}

.diagnostic-heading {
  position: relative;
  padding-bottom: 25px;

  .diagnostic-status-controls {
    position: absolute;
    right:0px;
    top:25px;
    font-size: 18px;
    text-align: right;
  }

  .status-label {
    font-weight: 600;
  }

  .status-output {
    color: $brand-purple;
  }

  .status-toggle {
    cursor: pointer;
    margin-top: 5px;
    color: $light-text;
    font-size: 15px;

    &:hover{
      color: $dark-text;
    }
  }

  .diagnostic-institution {
    text-align: center;
      font-size: 18px;
    padding-bottom: 20px;
  }

  .diagnostic-description {
    color: $light-text;
    font-size: 18px;
  }
}

.diagnostic-content {
  width:100%;
  position: relative;
  z-index: 100;
  transition: transform 0.2s ease-in;

  .diagnostic-content-holder{
    padding: 0 40px;
  }
  .page-title {
    font-size: 30px;
  }

  &.menu-open{
    transform: translateX(270px);
    width: calc(100vw - 270px);
  }
}

.scroll-menu {
  font-family: $open-sans;
  font-size: 14px;
  position: fixed;
  width: 270px;
  left: 0;
  top:45px;
  z-index: 900;
  height: calc(100vh - 45px);
  transform: translateX(-270px);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in, height 100ms linear;
  &.open {
    transform: translateX(0px);
  }

  &.pin-top{
    height:100vh;
  }
}

.menu-heading {
  padding: 0.625rem;
  border-bottom: 1px solid $divider-grey;
  position: relative;

  .menu-toggle {
    position: absolute;
    top: 0.39rem;
    font-size: 1.375rem;
    color: #666666;
    right:-1.7rem;
    &.is-open{
      right: 0.625rem;
    }
  }

  .diagnostic-name {
    width: 226px;
    word-wrap: break-word;
    display: inline-block;
  }
}



.parent-menu,
.child-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.parent-menu-item,
.child-menu-item {
  background: #FFF;
}

.child-menu-item.action-count {
  color:$brand-purple;
  font-weight: 500;
}

.parent-menu-item {
  &.active-item {
    // border: 1px solid purple;
  }
}

.child-item-link {
  padding:10px 10px 10px 20px;
  max-width: 100%;
  &:hover{
    background: #CCC;
  }
  &.active-child-item {
    border-right: 4px solid #883C8A;
  }
}

.parent-item-link {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1px solid $divider-grey;
  &:hover{
    background: #CCC;
  }
}

.active-item .parent-item-link {
  border-bottom: none;
}

.child-menu-wrap {
  overflow: hidden;
  transition: max-height 0.3s ease;
  scroll-behavior: smooth;

  &.not-active {
    max-height: 0!important;
  }
}

.active-item .child-menu-wrap {
  overflow-y: auto;
  border-top: 1px solid $divider-grey;
  border-bottom: 1px solid $divider-grey;
}

.menu-action-btns {
  padding: 15px;
  border-top: 1px solid $divider-grey;
  width: 100%;
  position: absolute;
  bottom: 0;

  .button + .button {
    margin-top: 15px;
  }
}

.hypothesis-text {
  b,
  strong {
    font-weight: 600!important;
  }

  ins {
    text-decoration: underline!important;
  }

  i,
  em {
    font-style: italic!important;
  }
}

.diagnostic-banner .ui-messages-error {
  border-bottom: 1px solid $divider-grey;
  z-index:1;
  .ui-messages-icon {
    padding-left: 10px;
  }

  .ui-messages-close {
    top: 20px;
    right: 10px;
    color: $dark-text;
    .fa-close:before{
      @extend .workspace-icon;
      content: "\6b";
    }
  }

  .ui-messages-summary {
    display: none;
  }

  .ui-messages-summary,
  .ui-messages-detail {
    color: $dark-text;
    font-family: $open-sans;
    font-weight: 300;
  }
}

.action-content-banner .ui-messages-error {
  border-bottom: 1px solid $divider-grey;
  .ui-messages-icon {
    padding-left: 10px;
  }

  .ui-messages-close {
    top: 20px;
    right: 10px;
    color: $dark-text;
    .fa-close:before{
      @extend .workspace-icon;
      content: "\6b";
    }
  }

  .ui-messages-summary {
    display: none;
  }

  .ui-messages-summary,
  .ui-messages-detail {
    color: $dark-text;
    font-family: $open-sans;
    font-weight: 300;
  }
}

.download-overlay,
.warning-overlay {
  border-radius: 10px;
  overflow: hidden;
  padding:20px;
  max-width: 800px!important;

  .ui-dialog-titlebar {
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 4px!important;
  }

  .ui-dialog-titlebar-close{
    color: $dark-text;
    position: absolute;
    font-size: 25px;
    font-weight: normal;
    right: -6px;
    top: -7px;
  }

  .ui-dialog-title {
    color: #282828;
    font-weight: 400;
    display: block;
    text-align: center;
    margin-top: 13px;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-line {
    padding-bottom: 30px;
  }

  .modal-text {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
    color: #999999;
  }

  .modal-subtext {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    color: $light-text;
  }

  .term-text {
    color:$dark-text;
  }
  .warning-msg {
    color:$warn-red;
    text-align: left;
    padding-bottom: 30px;
  }
  .button-row {
    text-align: right;
  }

  .button {
    padding-left: 48px;
    padding-right: 48px;
    margin-left: 20px;
  }
  .agree-term-form {
    padding-top: 10px;
    color:$dark-text;
    a {
      color:#3992D2;
    }
  }
}

.warning-overlay {
  .ui-dialog-title {
    color: $brand-purple;
    text-align: left;
    margin-top: 13px;
  }

  .ui-dialog-titlebar {
    padding-left: 0!important;
  }
}
.scroll-section-head {
  font-weight: 600;
  color: $brand-purple;
  font-size: 20px;
  padding-bottom: 10px;
}

.scroll-section-wrap {
  &.at-bottom {
    padding-bottom:100vh;
  }
}

.scroll-child-wrap {
  position: relative;
  margin-bottom: 25px;
  @extend .clr;
}

.diagnostic-heading {
  position: relative;
  padding-bottom: 25px;

  .diagnostic-status-controls {
    position: absolute;
    right:0px;
    top:25px;
    font-size: 18px;
    text-align: right;
  }

  .status-label {
    font-weight: 600;
  }

  .status-output {
    color: $brand-purple;
  }

  .status-toggle {
    cursor: pointer;
    margin-top: 5px;
    color: $light-text;
    font-size: 15px;

    &:hover{
      color: $dark-text;
    }
  }

  .diagnostic-institution {
    text-align: center;
      font-size: 18px;
    padding-bottom: 20px;
  }

  .diagnostic-description {
    color: $light-text;
    font-size: 18px;
  }
}

.diagnostic-content {
  width:100%;
  position: relative;
  z-index: 100;
  transition: transform 0.2s ease-in;

  .diagnostic-content-holder{
    padding: 0 40px;
  }
  .page-title {
    font-size: 30px;
  }

  &.menu-open{
    transform: translateX(270px);
    width: calc(100vw - 270px);
  }
}


.scroll-menu {
  font-family: $open-sans;
  font-size: 14px;
  position: fixed;
  width: 270px;
  left: 0;
  top:45px;
  z-index: 900;
  height: calc(100vh - 45px);
  transform: translateX(-270px);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in, height 100ms linear;
  &.open {
    transform: translateX(0px);
  }

  &.pin-top{
    height:100vh;
  }
}

.menu-heading {
  padding: 0.625rem;
  border-bottom: 1px solid $divider-grey;
  position: relative;

  .menu-toggle {
    position: absolute;
    top: 0.39rem;
    font-size: 1.375rem;
    color: #666666;
    right:-1.7rem;
    &.is-open{
      right: 0.625rem;
    }
  }

  .diagnostic-name {
    width: 226px;
    word-wrap: break-word;
    display: inline-block;
  }
}



.parent-menu,
.child-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.parent-menu-item,
.child-menu-item {
  background: #FFF;
}

.child-menu-item.action-count {
  color:$brand-purple;
  font-weight: 500;
}

.parent-menu-item {
  &.active-item {
    // border: 1px solid purple;
  }
}

.child-item-link {
  padding:10px 10px 10px 20px;
  cursor: pointer;
  width: 100%;
  display: block;

  &:hover{
    background: #CCC;
  }
  &.active-child-item {
    border-right: 4px solid #883C8A;
  }
}

.parent-item-link {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1px solid $divider-grey;
  &:hover{
    background: #CCC;
  }
}

.active-item .parent-item-link {
  border-bottom: none;
}

.child-menu-wrap {
  overflow: hidden;
  transition: max-height 0.3s ease;
  scroll-behavior: smooth;

  &.not-active {
    max-height: 0!important;
  }
}

.active-item .child-menu-wrap {
  overflow-y: auto;
  border-top: 1px solid $divider-grey;
  border-bottom: 1px solid $divider-grey;
}

.menu-action-btns {
  padding: 15px;
  border-top: 1px solid $divider-grey;
  width: 100%;
  position: absolute;
  bottom: 0;

  .button + .button {
    margin-top: 15px;
  }
}

.hypothesis-text {
  b,
  strong {
    font-weight: 600!important;
  }

  ins {
    text-decoration: underline!important;
  }

  i,
  em {
    font-style: italic!important;
  }
}

.diagnostic-banner .ui-messages-error {
  border-bottom: 1px solid $divider-grey;
  .ui-messages-icon {
    padding-left: 10px;
  }

  .ui-messages-close {
    top: 20px;
    right: 10px;
    color: $dark-text;
    .fa-close:before{
      @extend .workspace-icon;
      content: "\6b";
    }
  }

  .ui-messages-summary {
    display: none;
  }

  .ui-messages-summary,
  .ui-messages-detail {
    color: $dark-text;
    font-family: $open-sans;
    font-weight: 300;
  }
}

.action-content-banner .ui-messages-error {
  border-bottom: 1px solid $divider-grey;
  .ui-messages-icon {
    padding-left: 10px;
  }

  .ui-messages-close {
    top: 20px;
    right: 10px;
    color: $dark-text;
    .fa-close:before{
      @extend .workspace-icon;
      content: "\6b";
    }
  }

  .ui-messages-summary {
    display: none;
  }

  .ui-messages-summary,
  .ui-messages-detail {
    color: $dark-text;
    font-family: $open-sans;
    font-weight: 300;
  }
}

.download-overlay,
.warning-overlay {
  border-radius: 10px;
  overflow: hidden;
  padding:20px;
  max-width: 800px!important;

  .ui-dialog-titlebar {
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 4px!important;
  }

  .ui-dialog-titlebar-close{
    color: $dark-text;
    position: absolute;
    font-size: 25px;
    font-weight: normal;
    right: -6px;
    top: -7px;
  }

  .ui-dialog-title {
    color: #282828;
    font-weight: 400;
    display: block;
    text-align: center;
    margin-top: 13px;
  }

  .term-text {
    color:$dark-text;
  }
  .warning-msg {
    color:$warn-red;
    text-align: center;
    padding-bottom: 30px;
    max-width: 100%;
  }
  .button-row {
    text-align: right;
  }

  .button {
    padding-left: 48px;
    padding-right: 48px;
    margin-left: 20px;
  }
  .agree-term-form {
    padding-top: 10px;
    color:$dark-text;
    a {
      color:#3992D2;
    }
  }
}

.warning-overlay {
  .ui-dialog-title {
    color: $brand-purple;
    text-align: left;
    margin-top: 13px;
  }

  .ui-dialog-titlebar {
    padding-left: 0!important;
  }
}


// custom styles that are needed for content that is loaded as innerHtml (from the db)
#findings-page {
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 10px;
  }

  .section-title {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .bullet-list {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 15px;
    list-style: none;

    li::before {
      content: "\2022";
      color: $main-brand-color;
      font-weight: bold;
      display: inline-block;
      width: 1.5em;
      margin-left: -1em;
    }

    li {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

@font-face {
	font-family: 'CartoGothic';
	font-weight: normal;
	font-style: normal;
	src:url("../assets/fonts/cartogothicpro-book-webfont.eot");
	src:url("../assets/fonts/cartogothicpro-book-webfont.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/cartogothicpro-book-webfont.woff") format("woff"),
  url("../assets/fonts/cartogothicpro-book-webfont.ttf") format("truetype"),
  url("../assets/fonts/cartogothicpro-book-webfont.svg#CartoGothic") format("svg");
}


@font-face {
	font-family: 'CartoGothic';
	font-weight: bold;
	font-style: normal;
	src: url("../assets/fonts/cartogothicpro-bold-webfont.eot");
	src: url("../assets/fonts/cartogothicpro-bold-webfont.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/cartogothicpro-bold-webfont.woff") format("woff"),
  url("../assets/fonts/cartogothicpro-bold-webfont.ttf") format("truetype"),
  url("../assets/fonts/cartogothicpro-bold-webfont.svg#CartoGothic") format("svg");
}

@font-face {
	font-family: 'CartoGothic';
	font-weight: 600;
	font-style: normal;
	src: url("../assets/fonts/cartogothicpro-bold-webfont.eot");
	src: url("../assets/fonts/cartogothicpro-bold-webfont.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/cartogothicpro-bold-webfont.woff") format("woff"),
  url("../assets/fonts/cartogothicpro-bold-webfont.ttf") format("truetype"),
  url("../assets/fonts/cartogothicpro-bold-webfont.svg#CartoGothic") format("svg");
}

@font-face {
	font-family: 'CartoGothic';
	font-weight: 300;
	font-style: normal;
	src: url("../assets/fonts/cartogothicpro-light-webfont.eot");
	src: url("../assets/fonts/cartogothicpro-light-webfont.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/cartogothicpro-light-webfont.woff") format("woff"),
  url("../assets/fonts/cartogothicpro-light-webfont.ttf") format("truetype"),
  url("../assets/fonts/cartogothicpro-light-webfont.svg#CartoGothic") format("svg");
}

@font-face {
  font-weight: normal;
	font-style: normal;
	font-family: "eleoticons";
	src: url("../assets/fonts/eleoticons.eot");
	src: url("../assets/fonts/eleoticons.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/eleoticons.woff") format("woff"),
  url("../assets/fonts/eleoticons.ttf") format("truetype"),
  url("../assets/fonts/eleoticons.svg#eleoticons") format("svg");
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-Regular-webfont.eot');
  src: url('../assets/fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/OpenSans-Regular-webfont.woff') format('woff'),
       url('../assets/fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
       url('../assets/fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-Bold-webfont.eot');
  src: url('../assets/fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/OpenSans-Bold-webfont.woff') format('woff'),
       url('../assets/fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
       url('../assets/fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/OpenSans-Semibold-webfont.eot');
    src: url('../assets/fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/OpenSans-Semibold-webfont.woff') format('woff'),
         url('../assets/fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('../assets/fonts/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-Bold-webfont.eot');
  src: url('../assets/fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/OpenSans-Bold-webfont.woff') format('woff'),
       url('../assets/fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
       url('../assets/fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

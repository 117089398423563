/*------------------------
	icons
-------------------------*/
@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

$icon-not-applicable: unicode(ea03);
$icon-open: unicode(ea07);
$icon-view: unicode(ea08);
$icon-applicable: unicode(ea0a);
$icon-email: unicode(ea0d);
$icon-share: unicode(ea0e);
$icon-closed-folder-16: unicode(ea0f);
$icon-notes: unicode(ea10);
$icon-warning-36: unicode(ea11);
$icon-download: unicode(ea12);
$icon-trash: unicode(ea13);
$icon-attachment-36: unicode(ea15);
$icon-calendar-2: unicode(ea17);
$icon-link: unicode(ea1b);
$icon-pdf: unicode(ea1c);
$icon-attachment-24: unicode(ea1d);
$icon-csv: unicode(ea1e);
$icon-image-file-type: unicode(ea1f);
$icon-strategies-results: unicode(ea20);
$icon-doc: unicode(ea21);
$icon-survey-results: unicode(ea22);
$icon-png: unicode(ea23);
$icon-general-file-type-2: unicode(ea24);
$icon-institution-flag: unicode(ea26);
$icon-warning-24: unicode(ea29);
$icon-complete: unicode(ea2a);
$icon-status-change: unicode(ea2b);
$icon-toggle-24: unicode(ea2d);
$icon-settings-cog: unicode(ea2e);
$icon-pdf-2-2: unicode(ea32);
$icon-edit-2: unicode(ea33);
$icon-warning-18: unicode(ea34);
$icon-comment-24: unicode(ea35);
$icon-icon-action: unicode(ea36);
$icon-comment-36: unicode(ea37);
$icon-comment-18: unicode(ea38);
$icon-icon-action-24: unicode(ea39);
$icon-notes-24: unicode(ea3a);
$icon-notifications: unicode(ea3b);
$icon-play: unicode(ea3c);
$icon-time-zone: unicode(ea3d);
$icon-unlock-24: unicode(ea3e);
$icon-lock-24: unicode(ea3f);
$icon-lock-18: unicode(ea40);
$icon-unlock-18: unicode(ea41);
$icon-complete-18: unicode(ea42);
$icon-icon-action-24-2: unicode(ea43);

.icon-not-applicable::before {
  content: $icon-not-applicable;
}

.icon-open::before {
  content: $icon-open;
}

.icon-view::before {
  content: $icon-view;
}

.icon-applicable::before {
  content: $icon-applicable;
}

.icon-email::before {
  content: $icon-email;
}

.icon-share::before {
  content: $icon-share;
}

.icon-closed-folder-16::before {
  content: $icon-closed-folder-16;
}

.icon-notes::before {
  content: $icon-notes;
}

.icon-warning-36::before {
  content: $icon-warning-36;
}

.icon-download::before {
  content: $icon-download;
}

.icon-trash::before {
  content: $icon-trash;
}

.icon-attachment-36::before {
  content: $icon-attachment-36;
}

.icon-calendar-2::before {
  content: $icon-calendar-2;
}

.icon-link::before {
  content: $icon-link;
}

.icon-pdf::before {
  content: $icon-pdf;
}

.icon-attachment-24::before {
  content: $icon-attachment-24;
}

.icon-csv::before {
  content: $icon-csv;
}

.icon-image-file-type::before {
  content: $icon-image-file-type;
}

.icon-strategies-results::before {
  content: $icon-strategies-results;
}

.icon-doc::before {
  content: $icon-doc;
}

.icon-survey-results::before {
  content: $icon-survey-results;
}

.icon-png::before {
  content: $icon-png;
}

.icon-general-file-type-2::before {
  content: $icon-general-file-type-2;
}

.icon-institution-flag::before {
  content: $icon-institution-flag;
}

.icon-warning-24::before {
  content: $icon-warning-24;
}

.icon-complete::before {
  content: $icon-complete;
}

.icon-status-change::before {
  content: $icon-status-change;
}

.icon-toggle-24::before {
  content: $icon-toggle-24;
}

.icon-settings-cog::before {
  content: $icon-settings-cog;
}

.icon-pdf-2-2::before {
  content: $icon-pdf-2-2;
}

.icon-edit-2::before {
  content: $icon-edit-2;
}

.icon-warning-18::before {
  content: $icon-warning-18;
}

.icon-comment-24::before {
  content: $icon-comment-24;
}

.icon-icon-action::before {
  content: $icon-icon-action;
}

.icon-comment-36::before {
  content: $icon-comment-36;
}

.icon-comment-18::before {
  content: $icon-comment-18;
}

.icon-icon-action-24::before {
  content: $icon-icon-action-24;
}

.icon-notes-24::before {
  content: $icon-notes-24;
}

.icon-notifications::before {
  content: $icon-notifications;
}

.icon-play::before {
  content: $icon-play;
}

.icon-time-zone::before {
  content: $icon-time-zone;
}

.icon-unlock-24::before {
  content: $icon-unlock-24;
}

.icon-lock-24::before {
  content: $icon-lock-24;
}

.icon-lock-18::before {
  content: $icon-lock-18;
}

.icon-unlock-18::before {
  content: $icon-unlock-18;
}

.icon-complete-18::before {
  content: $icon-complete-18;
}

.icon-icon-action-24-2::before {
  content: $icon-icon-action-24-2;
}


.detail-panel {
  background: #FFF;
  transition: all 200ms ease-in;
  z-index: 1000;
  right:-700px;
  top:0px;
  bottom:0px;
  position: absolute;
  width: 700px;
  overflow: auto;
}


.detail-header {
  background:$accent-grey;
  padding: 15px 15px 15px 25px;
  position: relative;


  .detail-close-btn {
    background: none;
    color: $blue-text;
    position: absolute;
    font-size: 20px;
    font-weight: 300;
    right:15px;
    top:8px;
  }

  .workspace-status {
    font-size: 25px;
    font-weight: 600;

    .status.active{
      color: $active-green;
    }
  }
}

.detail-label {
  font-size: 12px;
  font-weight: 300;
  color:$text-base;
  padding: 5px 0;
}

.detail-text {
  font-size: 15px;
}

// Overlay active
.overlay-lock.detail-active .detail-panel {
  right:0px;
  box-shadow: 0 -1px 10px rgba(0,0,0,.3);
}

.send-email-confirm .add-warning {
  color: #000!important;
}

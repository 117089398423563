body {
  font-family: $carto;
  font-size: 16px;
  font-weight: normal;
  background-color: #FFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-content {
  position: relative;
  min-height: 100vh;
  transition: transform 500ms ease 0s;
}

.page-title {
  font-family: $carto;
  font-size: 1.6875rem;
  text-align: center;
  line-height: 1.4;
  font-weight: bold;
  color:$brand-purple;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  padding: 17px 25px;
  // border-bottom: 1px solid $blue-text;
}

// app- selectors are new and standardize styling for Action Plans, etc. ==========================

.app-page-content {
  width: 90%;
  max-width: 64rem; // 1024px;
  margin: 0 auto;
  @include h1();
  @include h2();
  @include h3();
  @include p();
  @include span();
  @include label();
}

.app-page-header {
  text-align: center;
  @include h1();
  @include h2();
  @include h3();
  @include p();
  @include span();
  @include label();
  p {
    text-align: left;
  }
  span {
    text-align: left;
  }
}

// app- selectors are new and standardize styling for Action Plans, etc. ==========================

.content-action-bar {
  background-color: #FFF;
  display: block;
  padding: 10px;
}

.main-content {
  min-height: calc(100vh - 56px);
  margin-bottom: 4rem;
}

.breadcrumbs {
  color: $brand-purple!important;

  .crumb-item {
    display: inline-block;
    color: $brand-purple!important;
  }

  .crumb-link {
    color: $brand-purple!important;
    font-weight: normal!important;
  }

  .crumb-link:hover {
    text-decoration: underline;
  }

  .crumb-item + .crumb-item {
    &:before{
      position: relative;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f105";
      padding: 0 10px;
    }
  }
}


.ui-menu .ui-menuitem .ui-menuitem-link {
  color:#000;
}

.highlight-text {
  display:inline-block;
  //EW-1395
  font-weight: bold;
  color: #565656;
}

.ui-messages	{
  border: none;
  padding: 1em;
  margin: 0;
  background: #fffee2;

  .ui-messages-close {
    color: #000;
    font-size: 29px;
    top: 17px;
    right: 1em;
  }

  .ui-messages-summary {
    position: relative;
    text-transform: capitalize;
  }

  .ui-messages-summary,
  .ui-messages-detail {
    color: #000;
    display: inline-block;
    min-height: 16px;
    min-width: 5px;
  }
}

// remove text decoration for activity view page
.activity-view-banner .ui-messages {
  .ui-messages-summary {
    text-transform: none;
  }
}

.ui-messages-success .ui-messages-icon {
    color: $brand-purple;
    &:before{
      font-family: FontAwesome;
      content: "\f05d";
    }
  }


.ui-messages-error {
  .ui-messages-icon {
    color: $warn-red;
    &:before{
      font-family: FontAwesome;
      content: "\f071";
    }
  }

  .ui-messages-summary,
  .ui-messages-detail {
    color: $warn-red;
  }
}

.tool-tip {
  cursor: default;
}

.main-loading-text-frame {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow:hidden;
  text-align: center;

  .main-loading-text {
    display: block;
    position: absolute;
    font-weight: bold;
    font-size: 27px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color:#873A8C;
  }
}

.my-container {
  padding: 15px 25px;
}

.divider {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $light-text;
  margin-top: 15px;
  margin-bottom: 15px;
}


.attachment-name-tooltip{
  width: auto;
  max-width: 400px;
  word-wrap: break-word;
}

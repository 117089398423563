.header-container {
  .user-info {
    cursor: pointer;
    color: #FFF;
    position: absolute;
    padding-top: 14px;
    top:0px;
    right:21px;
    padding-right: 0px;
    padding-bottom: 17px;


  .welcome-text,
  .user-name,
    i {
      font-size: 14px;
      padding-left: 7px;
    }

    .welcome-text {
      font-weight: 600;
    }

    .user-name {
      font-weight:300;
    }

    .user-menu {
      display: none;
      position: absolute;
      z-index: 1000;
      background:#FFF;
      box-shadow: 0 0 3px rgba(0,0,0,.7);
      right: 0px;
      top:40px;
      color: #000;
      width: 130px;
      text-align: left;

      &:hover {
        display: block;
      }

    }

    &:hover .user-menu{
      display: block;
    }
  }
}

.admin-header .header-container {

  .user-info {
    color:$dark-text;
  }

  .user-menu {
    top:48px;
  }
}

@import "../vars-mixins";
.clearfix {
    &::after {
        clear: both;
        display: block;
        content: "";
        height: 0;
    }
}
.hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.workspace-theme {
    &:focus {
        outline: 0;
    }
    .fr-element {
        -webkit-user-select: auto;
        table {
            td.fr-selected-cell {
                border: 1px double $brand-purple;
            }
            th.fr-selected-cell {
                border: 1px double $brand-purple;
            }
        }
        .fr-video {
            &::after {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
    &.mod-dark {
        .fr-wrapper {
            .fr-placeholder {
                //z-index: 99;
                //color: #a6a6a6;
            }
        }
    }
    .fr-wrapper {
        .fr-placeholder {
            font-size: 12px;
            color: #aaa;
            top: 0;
            left: 0;
            right: 0;
        }
        &::selection {
            background: #b5d6fd;
            color: #000;
        }
        &::-moz-selection {
            background: #b5d6fd;
            color: #000;
        }
    }
    .fr-sticky-on.fr-sticky-ios {
        left: 0;
        right: 0;
    }
    textarea.fr-code {
        background: #fff;
        color: #000;
    }
    .fr-drag-helper {
        background: $brand-purple;
    }
    .fr-file-upload-layer {
        border: dashed 2px #bdbdbd;
        padding: 25px 0;
        &:hover {
            background: #ebebeb;
        }
        .fr-form {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    .fr-file-upload-layer.fr-drop {
        background: #ebebeb;
        border-color: $brand-purple;
    }
    .fr-file-progress-bar-layer {
        >h3 {
            margin: 10px 0;
        }
        >div.fr-loader {
            background: #f1d1d1;
            span {
                background: $brand-purple;
                -webkit-transition: width 0.2s ease 0s;
                -moz-transition: width 0.2s ease 0s;
                -ms-transition: width 0.2s ease 0s;
                -o-transition: width 0.2s ease 0s;
            }
        }
        >div.fr-loader.fr-indeterminate {
            span {
                top: 0;
            }
        }
    }
    .fr-image-resizer {
        border: solid 1px $brand-purple;
        .fr-handler {
            background: $brand-purple;
            border: solid 1px #fff;
            width: 12px;
            height: 12px;
        }
        .fr-handler.fr-hnw {
            left: -6px;
            top: -6px;
        }
        .fr-handler.fr-hne {
            right: -6px;
            top: -6px;
        }
        .fr-handler.fr-hsw {
            left: -6px;
            bottom: -6px;
        }
        .fr-handler.fr-hse {
            right: -6px;
            bottom: -6px;
        }
    }
    .fr-image-upload-layer {
        border: dashed 2px #bdbdbd;
        padding: 25px 0;
        &:hover {
            background: #ebebeb;
        }
        .fr-form {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    .fr-image-upload-layer.fr-drop {
        background: #ebebeb;
        border-color: $brand-purple;
    }
    .fr-image-progress-bar-layer {
        >h3 {
            margin: 10px 0;
        }
        >div.fr-loader {
            background: #f1d1d1;
            span {
                background: $brand-purple;
                -webkit-transition: width 0.2s ease 0s;
                -moz-transition: width 0.2s ease 0s;
                -ms-transition: width 0.2s ease 0s;
                -o-transition: width 0.2s ease 0s;
            }
        }
        >div.fr-loader.fr-indeterminate {
            span {
                top: 0;
            }
        }
    }
    .fr-line-breaker {
        border-top: 1px solid $brand-purple;
        a.fr-floating-btn {
            left: calc(34%);
            top: -16px;
        }
    }
    .fr-qi-helper {
        padding-left: 10px;
        a.fr-btn.fr-floating-btn {
            color: #565656;
        }
    }
    .fr-table-resizer {
        div {
            border-right: 1px solid $brand-purple;
        }
    }
    .fr-video-upload-layer {
        border: dashed 2px #bdbdbd;
        padding: 25px 0;
        &:hover {
            background: #ebebeb;
        }
        .fr-form {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    .fr-video-upload-layer.fr-drop {
        background: #ebebeb;
        border-color: $brand-purple;
    }
    .fr-video-progress-bar-layer {
        >h3 {
            margin: 10px 0;
        }
        >div.fr-loader {
            background: #f1d1d1;
            span {
                background: $brand-purple;
                -webkit-transition: width 0.2s ease 0s;
                -moz-transition: width 0.2s ease 0s;
                -ms-transition: width 0.2s ease 0s;
                -o-transition: width 0.2s ease 0s;
            }
        }
        >div.fr-loader.fr-indeterminate {
            span {
                top: 0;
            }
        }
    }
    .fr-view {
        font-size: 14px;
        strong {
            font-weight: 700;
        }
        table.fr-alternate-rows {
            tbody {
                tr {
                    &:nth-child(2n) {
                        background: #f5f5f5;
                    }
                }
            }
        }
        table {
            td {
                border: 1px solid #ddd;
            }
            th {
                border: 1px solid #ddd;
                background: #e6e6e6;
            }
        }
        blockquote {
            border-left: solid 2px #5e35b1;
            margin-left: 0;
            color: #5e35b1;
            blockquote {
                border-color: #00bcd4;
                color: #00bcd4;
                blockquote {
                    border-color: #43a047;
                    color: #43a047;
                }
            }
        }
        span.fr-emoticon {
            line-height: 0;
        }
        span.fr-emoticon.fr-emoticon-img {
            font-size: inherit;
        }
        .fr-text-bordered {
            padding: 10px 0;
        }
    }
    .fr-view[dir="rtl"] {
        blockquote {
            border-right: solid 2px #5e35b1;
            margin-right: 0;
            blockquote {
                border-color: #00bcd4;
                blockquote {
                    border-color: #43a047;
                }
            }
        }
    }
    .fr-tooltip {
        top: 0;
        left: 0;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background: #222;
        color: #fff;
        font-size: 11px;
        line-height: 22px;
        font-family: Arial, Helvetica, sans-serif;
        -webkit-transition: opacity 0.2s ease 0s;
        -moz-transition: opacity 0.2s ease 0s;
        -ms-transition: opacity 0.2s ease 0s;
        -o-transition: opacity 0.2s ease 0s;
    }

    .fr-command.fr-btn.fr-active {
        + {
            .fr-dropdown-menu {
                -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
                -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
                .fr-dropdown-wrapper {
                    height: auto;
                    max-height: 275px;
                }
            }
        }
    }
    .fr-bottom {
        >.fr-command.fr-btn {
            + {
                .fr-dropdown-menu {
                    border-radius: 4px 4px 0 0;
                    -moz-border-radius: 4px 4px 0 0;
                    -webkit-border-radius: 4px 4px 0 0;
                    -moz-background-clip: padding;
                    -webkit-background-clip: padding-box;
                    background-clip: padding-box;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                }
            }
        }
    }
    .fr-separator {
        background: #ebebeb;
    }
    .fr-separator.fr-vs {
        height: 34px;
        width: 1px;
        margin: 2px;
    }
    .fr-separator.fr-hs {
        height: 1px;
        width: calc(96%);
        margin: 0 2px;
    }
    .fr-second-toolbar {
        border: none;
    }
}
.workspace-theme.fr-box.fr-basic {
    &.mod-dark {
        .fr-element.fr-view {
            //background: #f6f6f6;
        }
    }
    .fr-element {
        color: #333333;
        padding: 10px;
        overflow-x: auto;
        min-height: 40px;
        border: none;
        ul {
            margin-left: 1.1rem;
            list-style-type: circle;
        }
    }
    border: 1px solid #bcbcbc;
    border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
    .fr-wrapper {
        background: #fff;
        border: 1px;
        border-top: 0;
        top: 0;
        left: 0;
        border-radius: 4px 4px 4px 4px;
        -moz-border-radius: 4px 4px 4px 4px;
        -webkit-border-radius: 4px 4px 4px 4px;
    }
    .fr-element.fr-view {
        >* {
            font-family: "Open Sans", Arial, Helvetica, sans-serif;
            font-size: 0.9375rem;
            font-weight: normal;
        }
    }
}
.workspace-theme.fr-box {
    a.fr-floating-btn {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: 32px;
        width: 32px;
        background: #fff;
        color: $brand-purple;
        -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
        -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
        -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
        -o-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
        left: 0;
        top: 0;
        line-height: 32px;
        border: none;
        svg {
            -webkit-transition: transform 0.2s ease 0s;
            -moz-transition: transform 0.2s ease 0s;
            -ms-transition: transform 0.2s ease 0s;
            -o-transition: transform 0.2s ease 0s;
            fill: $brand-purple;
        }
        i {
            font-size: 14px;
            line-height: 32px;
        }
        &:hover {
            background: #ebebeb;
            svg {
                fill: $brand-purple;
            }
        }
    }
    .fr-counter {
        display: none;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 12px;
        color: #bcbcbc;
        background: #fff;
        border: 1px solid #bcbcbc;
        min-width: 25px;
        text-align: center;
        border-radius: 4px 0 4px 0;
        -moz-border-radius: 4px 0 4px 0;
        -webkit-border-radius: 4px 0 4px 0;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }
    .fr-video-resizer {
        border: solid 1px $brand-purple;
        .fr-handler {
            background: $brand-purple;
            border: solid 1px #fff;
            width: 12px;
            height: 12px;
        }
        .fr-handler.fr-hnw {
            left: -6px;
            top: -6px;
        }
        .fr-handler.fr-hne {
            right: -6px;
            top: -6px;
        }
        .fr-handler.fr-hsw {
            left: -6px;
            bottom: -6px;
        }
        .fr-handler.fr-hse {
            right: -6px;
            bottom: -6px;
        }
    }
}
.workspace-theme.fr-box.fr-basic.fr-top {
    .fr-wrapper {
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}
.workspace-theme.fr-box.fr-basic.fr-bottom {
    .fr-wrapper {
        border-bottom: 0;
        border-radius: 4px 4px 0 0;
        -moz-border-radius: 4px 4px 0 0;
        -webkit-border-radius: 4px 4px 0 0;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}
.workspace-theme.fr-box.fr-rtl {
    .fr-counter {
        display: none;
        right: auto;
        border-right: solid 1px #ebebeb;
        border-radius: 0 4px 0 0;
        -moz-border-radius: 0 4px 0 0;
        -webkit-border-radius: 0 4px 0 0;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }
}
.workspace-theme.fr-box.fr-code-view.fr-inline {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.workspace-theme.fr-box.fr-inline {
    .fr-command.fr-btn.html-switch {
        top: 0;
        right: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background: #fff;
        color: #565656;
        -moz-outline: 0;
        outline: 0;
        border: 0;
        padding: 12px 12px;
        -webkit-transition: background 0.2s ease 0s;
        -moz-transition: background 0.2s ease 0s;
        -ms-transition: background 0.2s ease 0s;
        -o-transition: background 0.2s ease 0s;
        i {
            font-size: 14px;
            width: 14px;
        }
    }
    .fr-command.fr-btn.html-switch.fr-desktop {
        &:hover {
            background: #ebebeb;
        }
    }
}
.workspace-theme.fr-popup {
    .fr-colors-tabs {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        .fr-colors-tab {
            color: #565656;
            padding: 8px 0;
            &:hover {
                color: $brand-purple;
            }
            &:focus {
                color: $brand-purple;
            }
        }
        .fr-colors-tab[data-param1="background"] {
            &::after {
                bottom: 0;
                left: 0;
                background: $brand-purple;
                -webkit-transition: transform 0.2s ease 0s;
                -moz-transition: transform 0.2s ease 0s;
                -ms-transition: transform 0.2s ease 0s;
                -o-transition: transform 0.2s ease 0s;
            }
        }
        .fr-colors-tab.fr-selected-tab {
            color: $brand-purple;
        }
    }
    .fr-color-set {
        line-height: 0;
        >span {
            >i {
                bottom: 0;
                left: 0;
            }
            .fr-selected-color {
                color: #fff;
                font-weight: 400;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
            }
            &:hover {
                outline: 1px solid #565656;
            }
            &:focus {
                outline: 1px solid #565656;
            }
        }
    }
    .fr-link {
        &:focus {
            background: #ebebeb;
        }
    }
    .fr-table-size {
        .fr-select-table-size {
            line-height: 0;
            >span {
                padding: 0px 4px 4px 0;
                >span {
                    border: 1px solid #ddd;
                }
            }
            >span.hover {
                >span {
                    background: rgba(207, 103, 103, 0.3);
                    border: solid 1px $brand-purple;
                }
            }
        }
    }
    .fr-table-colors {
        line-height: 0;
        >span {
            >i {
                bottom: 0;
                left: 0;
            }
            &:focus {
                outline: 1px solid #565656;
            }
        }
    }
    .fr-command.fr-btn {
        color: #565656;
        -moz-outline: 0;
        outline: 0;
        border: 0;
        margin: 0px 2px;
        -webkit-transition: background 0.2s ease 0s;
        -moz-transition: background 0.2s ease 0s;
        -ms-transition: background 0.2s ease 0s;
        -o-transition: background 0.2s ease 0s;
        padding: 0;
        width: 38px;
        height: 38px;
        &::-moz-focus-inner {
            border: 0;
        }
        i {
            font-size: 14px;
            width: 14px;
            margin: 12px 12px;
        }
        span {
            font-size: 14px;
            line-height: 17px;
            min-width: 38px;
            height: 17px;
            padding: 0 2px;
        }
        img {
            margin: 12px 12px;
            width: 14px;
        }
    }
    .fr-command.fr-btn.fr-btn-text {
        width: auto;
    }
    .fr-command.fr-btn.fr-active {
        color: $brand-purple;
        background: transparent;
    }
    .fr-command.fr-btn.fr-dropdown.fr-selection {
        width: auto;
    }
    .fr-command.fr-btn.fr-dropdown {
        i {
            margin-left: 8px;
            margin-right: 16px;
        }
        span {
            margin-left: 8px;
            margin-right: 16px;
        }
        img {
            margin-left: 8px;
            margin-right: 16px;
        }
        &::after {
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #565656;
            right: 4px;
            top: 17px;
        }
    }
    .fr-command.fr-btn.fr-dropdown.fr-active {
        color: #565656;
        background: #d6d6d6;
        &:hover {
            background: #d6d6d6 !important;
            color: #565656 !important;
            &::after {
                border-top-color: #565656 !important;
            }
        }
        &:focus {
            background: #d6d6d6 !important;
            color: #565656 !important;
            &::after {
                border-top-color: #565656 !important;
            }
        }
    }
    .fr-command.fr-btn.fr-disabled {
        color: #bdbdbd;
        &::after {
            border-top-color: #bdbdbd !important;
        }
    }
    color: #565656;
    background: #fff;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-family: Arial, Helvetica, sans-serif;
    border: 1px;
    border-top: 5px solid $brand-purple;
    .fr-input-focus {
        background: #f5f5f5;
    }
    .fr-input-line {
        padding: 8px 0;
        input[type="text"] {
            margin: 0px 0 1px 0;
            border-bottom: solid 1px #bdbdbd;
            color: #565656;
            &:focus {
                border-bottom: solid 2px $brand-purple;
            }
        }
        textarea {
            margin: 0px 0 1px 0;
            border-bottom: solid 1px #bdbdbd;
            color: #565656;
            &:focus {
                border-bottom: solid 2px $brand-purple;
            }
            + {
                label {
                    top: 0;
                    left: 0;
                    -webkit-transition: color 0.2s ease 0s;
                    -moz-transition: color 0.2s ease 0s;
                    -ms-transition: color 0.2s ease 0s;
                    -o-transition: color 0.2s ease 0s;
                    background: #fff;
                }
            }
        }
        input {
            + {
                label {
                    top: 0;
                    left: 0;
                    -webkit-transition: color 0.2s ease 0s;
                    -moz-transition: color 0.2s ease 0s;
                    -ms-transition: color 0.2s ease 0s;
                    -o-transition: color 0.2s ease 0s;
                    background: #fff;
                }
            }
        }
        input.fr-not-empty {
            &:focus {
                + {
                    label {
                        color: $brand-purple;
                    }
                }
            }
            + {
                label {
                    color: #808080;
                }
            }
        }
        textarea.fr-not-empty {
            &:focus {
                + {
                    label {
                        color: $brand-purple;
                    }
                }
            }
            + {
                label {
                    color: #808080;
                }
            }
        }
    }
    .fr-buttons {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        padding: 0 2px;
        line-height: 0;
        border-bottom: 1px;
    }
    .fr-layer {
        width: 225px;
    }
    .fr-action-buttons {
        button {
            background-color: $brand-purple;
            color: white;
            &::-moz-focus-inner {
                border: 0;
            }
        }
        button.fr-command {
            -webkit-transition: background 0.2s ease 0s;
            -moz-transition: background 0.2s ease 0s;
            -ms-transition: background 0.2s ease 0s;
            -o-transition: background 0.2s ease 0s;
            &:hover {
                background: lighten($brand-purple, 10%);
                color: white;
            }
            &:focus {
                background: lighten($brand-purple, 10%);
                color: white;
            }
            &:active {
                background: $brand-purple;
                color: white;
            }
        }
    }
    .fr-checkbox {
        span {
            border: solid 1px #565656;
            border-radius: 4px;
            -moz-border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-background-clip: padding;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
            -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
            -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
            -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
        }
        input {
            margin: 0;
            padding: 0;
            &:checked {
                + {
                    span {
                        background: $brand-purple;
                        border-color: $brand-purple;
                    }
                }
            }
            &:focus {
                + {
                    span {
                        border-color: $brand-purple;
                    }
                }
            }
        }
    }
    .fr-arrow {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $brand-purple;
        top: -9px;
        margin-left: -5px;
    }
}
.workspace-theme.fr-box.fr-fullscreen {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.workspace-theme.fr-modal {
    .fr-modal-wrapper {
        .fr-modal-body {
            .fr-help-modal {
                table {
                    tr {
                        border: 0;
                    }
                    tbody {
                        tr {
                            border-bottom: solid 1px #ebebeb;
                        }
                        td {
                            &:first-child {
                                color: #898989;
                            }
                        }
                    }
                }
            }
            .fr-special-characters-modal {
                .fr-special-character {
                    border: 1px solid #ccc;
                }
            }
        }
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        margin: 20px auto;
        background: #fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border: 1px;
        border-top: 5px solid $brand-purple;
        .fr-modal-head {
            background: #fff;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            border-bottom: 1px;
            -webkit-transition: height 0.2s ease 0s;
            -moz-transition: height 0.2s ease 0s;
            -ms-transition: height 0.2s ease 0s;
            -o-transition: height 0.2s ease 0s;
            i {
                color: #565656;
            }
            i.fr-modal-close {
                top: 0;
                right: 0;
                -webkit-transition: color 0.2s ease 0s;
                -moz-transition: color 0.2s ease 0s;
                -ms-transition: color 0.2s ease 0s;
                -o-transition: color 0.2s ease 0s;
            }
            h4 {
                margin: 0;
                font-weight: 400;
            }
        }
    }
    color: #565656;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.workspace-theme.fr-image-overlay {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.workspace-theme.fr-modal-head {
    .fr-modal-head-line {
        i.fr-modal-more {
            -webkit-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
            -moz-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
            -ms-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
            -o-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
        }
        i.fr-modal-more.fr-not-available {
            opacity: 0;
            width: 0;
            padding: 12px 0;
        }
    }
    .fr-modal-tags {
        a {
            opacity: 0;
            border-radius: 4px;
            -moz-border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-background-clip: padding;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            color: $brand-purple;
            -webkit-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
            -moz-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
            -ms-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
            -o-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
        }
        a.fr-selected-tag {
            background: #d6d6d6;
        }
    }
}
.workspace-themediv.fr-modal-body {
    .fr-preloader {
        margin: 50px auto;
    }
    div.fr-image-list {
        padding: 0;
        div.fr-image-container {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            border-radius: 4px;
            -moz-border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-background-clip: padding;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            img {
                -webkit-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
                -moz-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
                -ms-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
                -o-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
            }
            .fr-delete-img {
                -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s;
                -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s;
                -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s;
                -o-transition: background 0.2s ease 0s, color 0.2s ease 0s;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                margin: 0;
                background: #b8312f;
                color: #fff;
            }
            .fr-insert-img {
                -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s;
                -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s;
                -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s;
                -o-transition: background 0.2s ease 0s, color 0.2s ease 0s;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                margin: 0;
                background: #fff;
                color: $brand-purple;
            }
        }
        div.fr-image-container.fr-image-deleting {
            &::after {
                -webkit-transition: opacity 0.2s ease 0s;
                -moz-transition: opacity 0.2s ease 0s;
                -ms-transition: opacity 0.2s ease 0s;
                -o-transition: opacity 0.2s ease 0s;
                background: #000;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
            &::before {
                color: #fff;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
            }
        }
        div.fr-image-container.fr-empty {
            background: #ccc;
            &::after {
                margin: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }
}
.workspace-theme.workspace-theme.fr-desktop {
    .fr-modal-wrapper {
        .fr-modal-head {
            .fr-modal-tags {
                a {
                    &:hover {
                        background: #ebebeb;
                    }
                }
                a.fr-selected-tag {
                    background: #d6d6d6;
                }
            }
            i {
                &:hover {
                    background: #ebebeb;
                }
            }
        }
        div.fr-modal-body {
            div.fr-image-list {
                div.fr-image-container {
                    .fr-delete-img {
                        &:hover {
                            background: #bf4644;
                            color: #fff;
                        }
                    }
                    .fr-insert-img {
                        &:hover {
                            background: #ebebeb;
                        }
                    }
                }
            }
        }
    }
}
.workspace-theme.fr-video-overlay {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.workspace-theme.fr-toolbar {
    .fr-command.fr-btn {
        color: #565656;
        -moz-outline: 0;
        outline: 0;
        border: 0;
        margin: 0px 2px;
        -webkit-transition: background 0.2s ease 0s;
        -moz-transition: background 0.2s ease 0s;
        -ms-transition: background 0.2s ease 0s;
        -o-transition: background 0.2s ease 0s;
        padding: 0;
        width: 38px;
        height: 38px;
        &::-moz-focus-inner {
            border: 0;
        }
        i {
            font-size: 14px;
            width: 14px;
            margin: 12px 12px;
        }
        span {
            font-size: 14px;
            line-height: 17px;
            min-width: 38px;
            height: 17px;
            padding: 0 2px;
        }
        img {
            margin: 12px 12px;
            width: 14px;
        }
        &.fr-options {
            //width: 16px;
        }
    }
    .fr-command.fr-btn.fr-btn-text {
        width: auto;
    }
    .fr-command.fr-btn.fr-active {
        color: $brand-purple;
        background: transparent;
    }
    .fr-command.fr-btn.fr-dropdown.fr-selection {
        width: auto;
    }
    .fr-command.fr-btn.fr-dropdown {
        i {
            margin-left: 8px;
            margin-right: 16px;
        }
        span {
            margin-left: 8px;
            margin-right: 16px;
        }
        img {
            margin-left: 8px;
            margin-right: 16px;
        }
    }
    .fr-command.fr-btn.fr-dropdown.fr-active {
        color: #565656;
        background: #d6d6d6;
        &:hover {
            background: #d6d6d6 !important;
            color: #565656 !important;
            &::after {
                border-top-color: #565656 !important;
            }
        }
        &:focus {
            background: #d6d6d6 !important;
            color: #565656 !important;
            &::after {
                border-top-color: #565656 !important;
            }
        }
    }
    .fr-command.fr-btn.fr-disabled {
        color: #bdbdbd;
        &::after {
            border-top-color: #bdbdbd !important;
        }
    }
    .fr-newline {
        display: none;
    }
    color: #565656;
    background: #fff;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 2px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #bcbcbc;
}
.workspace-theme.fr-toolbar.fr-disabled {
    .fr-btn {
        color: #bdbdbd;
    }
    .fr-btn.fr-active {
        color: #bdbdbd;
    }
    .fr-btn.fr-dropdown {
        &::after {
            border-top-color: #bdbdbd;
        }
    }
    .fr-btn.fr-active.fr-dropdown {
        &::after {
            border-top-color: #bdbdbd;
        }
    }
}
.workspace-theme.fr-popup.fr-disabled {
    .fr-btn {
        color: #bdbdbd;
    }
    .fr-btn.fr-active {
        color: #bdbdbd;
    }
    .fr-btn.fr-dropdown {
        &::after {
            border-top-color: #bdbdbd;
        }
    }
    .fr-btn.fr-active.fr-dropdown {
        &::after {
            border-top-color: #bdbdbd;
        }
    }
}
.workspace-theme.fr-desktop {
    .fr-command {
        &:hover {
            color: #565656;
            background: #ebebeb;
            &::after {
                border-top-color: #565656 !important;
            }
        }
        &:focus {
            color: #565656;
            background: #ebebeb;
            &::after {
                border-top-color: #565656 !important;
            }
        }
    }
    .fr-command.fr-selected {
        color: #565656;
        background: #d6d6d6;
    }
    .fr-command.fr-active {
        &:hover {
            color: $brand-purple;
            background: #ebebeb;
        }
        &:focus {
            color: $brand-purple;
            background: #ebebeb;
        }
    }
    .fr-command.fr-active.fr-selected {
        color: $brand-purple;
        background: #d6d6d6;
    }
}
.workspace-theme.fr-toolbar.fr-mobile {
    .fr-command.fr-blink {
        background: transparent;
    }
}
.workspace-theme.fr-popup.fr-mobile {
    .fr-command.fr-blink {
        background: transparent;
    }
}
.workspace-theme.fr-overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
}
.workspace-theme.fr-popup.fr-above {
    border-top: 0;
    border-bottom: 5px solid $brand-purple;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    .fr-arrow {
        top: auto;
        bottom: -9px;
        border-bottom: 0;
        border-top: 5px solid $brand-purple;
    }
}
.workspace-theme.fr-popup.fr-rtl {
    .fr-input-line {
        input {
            + {
                label {
                    left: auto;
                    right: 0;
                }
            }
        }
        textarea {
            + {
                label {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}
.workspace-theme.fr-toolbar.fr-inline {
    .fr-arrow {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $brand-purple;
        top: -9px;
        margin-left: -5px;
    }
}
.workspace-theme.fr-toolbar.fr-inline.fr-above {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom: 5px solid $brand-purple;
    border-top: 0;
    .fr-arrow {
        top: auto;
        bottom: -9px;
        border-bottom: 0;
        border-top-color: inherit;
        border-top-width: 5px;
    }
}
.workspace-theme.fr-toolbar.fr-top {
    top: 0;
    border-bottom: 1px solid #bcbcbc;
    border-top: none;
    border-right: none;
    border-left: none;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.workspace-theme.fr-toolbar.fr-bottom {
    bottom: 0;
    border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.item-tag {
    font-size: 14px;
    font-weight: 300;
}
.remaining-container {
    text-align: right;
}
.item-subtag {
    font-size: 14px;
}
.froala-editor {
    margin-bottom: 5px;
}
@media (min-width: 1200px) {
    .workspace-theme {
        .fr-image-resizer {
            .fr-handler {
                width: 10px;
                height: 10px;
            }
            .fr-handler.fr-hnw {
                left: -5px;
                top: -5px;
            }
            .fr-handler.fr-hne {
                right: -5px;
                top: -5px;
            }
            .fr-handler.fr-hsw {
                left: -5px;
                bottom: -5px;
            }
            .fr-handler.fr-hse {
                right: -5px;
                bottom: -5px;
            }
        }
    }
    .workspace-theme.fr-box {
        .fr-video-resizer {
            .fr-handler {
                width: 10px;
                height: 10px;
            }
            .fr-handler.fr-hnw {
                left: -5px;
                top: -5px;
            }
            .fr-handler.fr-hne {
                right: -5px;
                top: -5px;
            }
            .fr-handler.fr-hsw {
                left: -5px;
                bottom: -5px;
            }
            .fr-handler.fr-hse {
                right: -5px;
                bottom: -5px;
            }
        }
    }
}
// defaults for froala-view lists
.fr-view {
    ul {
        margin-left: 20px;
        list-style-type: circle;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .workspace-theme.fr-modal {
        .fr-modal-wrapper {
            margin: 30px auto;
        }
    }
}
@media (min-width: 992px) {
    .workspace-theme.fr-modal {
        .fr-modal-wrapper {
            margin: 50px auto;
        }
    }
}
@media (min-width: 768px) {
    .workspace-theme.fr-popup {
        .fr-layer {
            width: 300px;
        }
    }
}

// styles for froalaView (read only RTF view)
.narrative.rich-text ul, .team-lead-msg.rich-text ul {
    padding-top: 8px;
    padding-bottom: 8px;
    li {
        margin-top: 4px;
    }
}

.fr-view em {
    // reset.css removes italic style, so we have to apply it here to preserve the style inside
    // froala editor / view
    font-style: italic;
}

@import 'fonts';
@import '_vars-mixins.scss';
.checkbox {
  display: inline-block;
  position: relative;
  margin-right: 0.6rem;
  width: 15px;
  height: 15px;
  vertical-align: middle;

  label {
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;

    &:after {
      opacity: 0;
    }

  }

  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      content: '\F00C';
      position: absolute;
      top: 1px;
      left: 1px;
      opacity: 1;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 0.75rem;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.check-purple {
  label {
    background: white;
    border: 1px solid $border-grey;
    border-radius: 4px;
  }
  input[type=checkbox] {
    &:checked + label:after {
      color: white;
    }
    &:checked + label {
      background: $brand-purple;
      border: 1px solid $brand-purple;
    }
    &:disabled + label {
      background: $disabled-grey;
      border: 1px solid $disabled-grey;
      cursor: not-allowed;
    }
    &:checked:disabled + label:after {
      color: white;
      cursor: not-allowed;
    }
  }
}

.form-error-msg {
  opacity: 0;
  color:$warn-red;
  transition: opacity 60ms ease-in;
  transition-delay: 70ms;
  min-height: 28px;
  font-size: 14px;
  &.active{
    opacity: 1;
  }
}

.description-count {
  text-align: right;
  color:$light-text;
  font-size: 14px;
  padding-top: 5px;
}

.error-message {
  color: $warn-red;
  display:block;
  font-size: 13px;
  font-weight: 600;
  padding-top: 5px;
}

.error-trim .error-message {
  position: relative;
  top:-16px;
}

.form-explainer {
  color: $light-text;
  text-align: center;
  line-height: 20px;
  padding-bottom: 40px;
  p{
    padding-bottom: 30px;
  }
  a {
    color:#508FB8;
  }
}



.form-header {
  font-weight: 600;
  color:$brand-purple;
  font-size: 25px;
  padding-bottom: 15px;
  border-bottom: 2px solid $light-text;
  margin-bottom: 15px;
}

.ui-widget,
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: $carto;
}

label,
.label-placeholder {
  //font-weight: 300;
  color: $dark-text;
  padding-bottom: 5px;
  font-size: 15px;
  display: inline-block;
}

input[type='text'],
input[type='password'],
textarea,
.ui-dropdown,
.input-placeholder,
select {
  border: 1px solid  $light-text;
  font-family: $open-sans;
  background-color: #FFF;
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border-radius: 3px;

  &.ng-invalid.ng-touched {
    border-color: $warn-red;
  }

  &:disabled{
    background: $base-grey;
    cursor: not-allowed;
  }

  @include placeholders{
    font-weight: 300;
    color:$light-text;
  };
}

.input-placeholder {
  border: none;
  padding-left:0;
}

.button-row {
  padding-top: 15px;
}

.button {
  background: $brand-purple;
  font-family: $carto;
  font-weight: bold;
  cursor: pointer;
  color:#FFF;
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
  border-radius: 3px;
  outline: 0;
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  line-height:1;

  &.inline-btn {
    display: inline-block;
    width: auto;
  }

  &:disabled{
    background: $light-text;
    cursor: not-allowed;
  }

  &.alt-btn{
    background: $button-alt;
    color: #A1A1A1;
  }

  .btn-load {
    display: none;
    position: relative;
    top:1px;
  }

  &.loading-state{
    .loading-text {
      padding-left: 3px;
    }

    .btn-load {
      display: inline-block;
    }
  }


}

.button-sm-email {
  @extend .button;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 0px;
  padding-bottom: 1px;
  top: -4px;
  position: relative;
}

.ui-inputtext.ui-state-focus,
.ui-inputtext:focus {
  box-shadow: none;
}

.ui-dropdown {
  width: 100%!important;
  padding: 5.5px;
  font-family: $carto;
  .ui-dropdown-trigger {
    border: none;
    background:transparent;
  }
  .fa {
    margin-top: .5em!important;
    margin-left: -.5em!important;
  }
  .fa:before {
    content: "\F078";
    color: $light-text;
  }
}

.ui-autocomplete {
  width: 100%;
  font-family: $carto;

  &:after{
    position: relative;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: "\f002";
    float: right;
    top: -27px;
    right: 10px;
    color: $light-text;
  }
}


.results-loading .ui-autocomplete:after,
.results-loading input:after{
    content: "\f110";
    animation: rotating 2s linear infinite;
}




.ui-inputtext.ui-state-focus,
.ui-inputtext:focus,
.ui-inputtext:enabled:hover {
  border-color:$light-text;
}

.ui-autocomplete-panel,
.ui-dropdown-panel  {
  border-radius: 0px;
  box-shadow: 0px 1px 1px rgba(0,0,0,.2);
}

.ui-autocomplete-panel .ui-autocomplete-list,
.ui-dropdown-panel  .ui-dropdown-list {
  padding: 0;
  background: #FAFAFA;
}

.ui-autocomplete-items {
  background: $base-grey;
  border-radius: 0;
}

.ui-autocomplete-panel .ui-autocomplete-list-item,
.ui-dropdown-panel .ui-dropdown-item	 {
  border-radius: 0px!important;
  padding: 5px 10px;

  &:hover {
    background: #FFF;
    color:inherit;
  }

  &.ui-state-highlight {
    background: #eeeeee;
    color: $dark-text;
  }
}

.auto-title {
//  font-weight: 600;
  color:#565656;
 // color:$light-text;
  font-size: 18px;
  padding-bottom: 3px;
}

.auto-location {
  display: flex;
  color:$light-text;
  font-size: 15px;
}
.auto-sub {
  display: inline-block;
  color:$light-text;
  font-size: 15px;
}
.auto-postalCode {
  display: inline-block;
  color:$light-text;
  font-size: 15px;
  margin-left: 5px;
}

p-autocomplete.ng-invalid.ng-touched input,
p-calendar.ng-dirty.ng-invalid .ui-calendar .ui-inputtext,
p-calendar.ng-dirty.ng-invalid .ui-calendar .ui-inputtext:hover {
  border-color: $warn-red!important;
}


p-calendar.ng-dirty.ng-invalid .ui-calendar button {
  border: 1px solid $warn-red!important;
  border-left: none!important;
}

.ui-calendar .ui-inputtext,
.ui-calendar .ui-inputtext:hover {
  border-right: none!important;
}



.ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-active {
  background-color: $brand-purple;
}

.ui-widget {
  font-family: inherit;
}

.ui-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
p-calendar.ng-dirty.ng-invalid > .ui-inputtext,
p-chips.ng-dirty.ng-invalid > .ui-inputtext,
p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
p-listbox.ng-dirty.ng-invalid .ui-inputtext,
p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
p-selectbutton.ng-dirty.ng-invalid .ui-button,
p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border-color:$warn-red;
}

.calendar-wrap {
  position: relative;
  .ui-calendar-button {
    position: absolute; // This prevents p-calendar inputs from breaking. Custom styling alters the default p-calendar style.
  }
  .mock-placeholder {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 8px;
    color:#757575;
  }

  p-calendar {
    position: relative;
  }

  input[type='text'],
  .ui-inputtext,
  .ui-calendar.ui-calendar-w-btn input {
    background:transparent;
    z-index: 10;
    border: .0625rem solid $light-text; // 1px
    &:focus{
      background:#FFF;
      background-color:#FFF;
    }
  }


  &.no-placeholder p-calendar + .mock-placeholder,
  p-calendar.ng-invalid + .mock-placeholder {
    display: none;
  }
}

.ui-calendar {
  width: calc(100% - 32px);
}

.ui-calendar .ui-datepicker { // Prevents the datepicker from being too big
  width: 50%;
  min-width: 17.1875rem; // 275px
}

p-calendar {
  .ui-calendar {
    .ui-button {
      border: .0625rem solid $light-text;
      color: $brand-purple;
      background: #FFF;
      &:enabled:hover,
      &:focus {
        border: .0625rem solid $light-text;
        background: #FFF;
        outline: 0 none;
        color: $brand-purple;
      }

      &:enabled:active {
        border: .0625rem solid $light-text;
        background: #FFF;
        color: $brand-purple;
      }
    }
    .ui-calendar-button {
      background: #fff;
      color: $brand-purple;
      border-color: $light-text;
      border-left: none;

      &.ui-button:enabled:hover, &.ui-button:enabled:focus {
        border-color: $light-text;
      }
    }
    &.ui-calendar-w-btn:hover, &.ui-calendar-w-btn:focus {
      .ui-button, .ui-button:enabled:hover {
        background: #fff;
        color: $brand-purple;
        border-right-color: $light-text;
        border-top-color: $light-text;
        border-bottom-color: $light-text;
      }
    }
  }
  &.ui-inputwrapper-focus {
    .ui-calendar {
      .ui-calendar-button {
        background: #fff;
        color: $brand-purple;
        border-right-color: $light-text;
        border-top-color: $light-text;
        border-bottom-color: $light-text;
      }
      .ui-button, .ui-button:enabled:hover {
        border-left: none;
      }
    }
  }
}

/*.ui-autocomplete-panel,*/
.results-count {
  border-top: 1px solid;
  color: #8c8c8c;
  height: 35px;
  margin-top: 10px;
  padding:5px;
  margin-left:-5px;
  /*span {
    line-height: 35px;
   // margin-top: 10px;
    //color: black;
    font-size: inherit;
  }*/
}

@import 'vars-mixins';
.ui-dialog.ui-widget.finding-add-note-modal {
  border-radius: 0.3125rem;
  width: 66%;
  min-width: 40rem; // 640px;
  max-width: 64rem; // 1024px;
  .ui-dialog-content {
    padding: 0rem;
  }
  .ui-dialog-titlebar {
    background: $brand-purple;
    color: $white;
    padding: $modal-title-bar-padding;
    text-align: center;
    font-size: $h2-font-size;
    p-header {
      @include flex-center-x-y();
    }
  }
  .ui-dialog-titlebar-close {
    display: none;
  }
}

.modal-body {
  max-height: 70vh;
  overflow-y: scroll;
  padding: $modal-body-padding;
  color: $modal-font-color;
  * {
    border-color: $modal-border-color; // Defines border-color for all items in modal.
  }

  .modal-instructions {
    font-size: 1rem; // 16px
    margin-bottom: $modal-instructions-margin-bottom;
  }

  .modal-body-content {
    padding: $modal-body-content-padding;
    margin-bottom: $modal-body-content-margin-bottom; // IE has issues displaying padding-bottom, so we use margin-bottom here.
    border-width: 0.063rem; // 1px
    border-style: solid;
    .ui-dropdown {
      background: $modal-input-background-color;
    }
    .ui-dropdown-label {
      color: $light-text;
      font-weight: $bold;
      background: transparent;
    }
    .ui-dropdown-trigger-icon {
      color: $light-text;
      font-weight: $bold;
    }
  }
}

.save-cancel-button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button:first-child {
    margin-right: 0.3125rem; // 5px;
  }
}

#finding-add-note-uploader {
  p-progressBar {
    display: none;
  }

  .resource-upload {
    position: relative;
  }

  .ui-fileupload-content {
    min-height: 6.25rem;
    background: $background-light-grey;
    border: none;
  }

  .ui-fileupload-files {
    width: 100%;
  }

  .ui-fileupload-buttonbar {
    background: transparent;
    position: absolute;
    z-index: 1;
    right: 1rem;
    top: 2rem;
    span {
      background: $brand-purple;
      border: 0.0625rem solid $brand-purple; // 1px;
    }
    .pi.pi-plus {
      display: none;
    }
    .ui-button-text {
      padding: .25rem 1rem;
    }
  }

  .ui-messages{
    display:none;
    width: calc(100% - 8.625rem);
    .ui-messages-close {
      z-index: 3;
      top: .2rem;
      right: 0rem;
    }
  }

  &.has-file {
    .ui-fileupload-choose {
      display: none;
    }
  }

}


.finding-add-note-modal {

  .ui-datepicker-calendar {
    td {
      a {
        cursor: pointer;
      }
    }
  }

  .remove-file-button {
    background: transparent !important;
    border: none;
    padding: 0 !important;
  }

  .remove-file-icon {
    vertical-align: middle;
  }

  #finding-add-note-uploader {
    &.has-file {
      .ui-fileupload-content {
        min-height: auto;
      }
    }
    &:not(.has-file) {
      .ui-fileupload-content {
        min-height: inherit;
      }
    }
  }

  .ui-fileupload-choose {
    span {
      color: $white;
    }
  }

  .modal-body-content {
    margin-bottom: 0 !important; // Needs important to override matching selector in _modal-styles without affecting other modals
    padding-bottom: 0 !important;
  }

  .add-note-modal-footer {
    display: flex;
    .save-cancel-button-row {
      flex: 1 0 auto;
    }
  }
  .ui-dialog-footer.ui-widget-content {
    padding: 1em 40px;
    border: none;
  }

  .name-wrap {
    display: flex;
    > div {
      display: inline-block;
      line-height: 36px;
      &:last-of-type {
        z-index: 9001;
        i {
          z-index: 9003;
          cursor: pointer;
          color: #565656;
          font-size: 1.5rem;
        }
      }
      &:first-of-type {
        display: inline-flex;
        flex-direction: row;
        flex: 1 0 auto;
        line-height: 36px;
        width: 100px; // Have to pick a low value and let the flex expand accordingly

        vertical-align: middle;
        i {
          position: relative;
          vertical-align: middle;
          line-height: 36px;
          margin-right: 8px;
        }
        span {
          vertical-align: middle;
          &.resource-size {
            color: #222222;
          }
        }
      }

      .resource-name {
        overflow: hidden;
        display: inline-block;
        position: relative;
        text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
      }
      .resource-size {
        display: inline-flex;
        flex-direction: row;
        flex: 1 0 auto;
        margin-right: 10px;
        margin-left: 8px;
      }
    }
  }
}

.panel-overlay {
  opacity: 0;
  transition: opacity 300ms linear;

  .loader-text {
    display: none;
  }
}

.overlay-lock {
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

.overlay-loader {
  display: none;
}

.overlay-lock.detail-active,
.overlay-lock.menu-active,
.overlay-lock.loading-active,
.overlay-lock.navigating-active ,
.overlay-lock.active .panel-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow:hidden;
}

.overlay-lock.detail-active .panel-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow:hidden;
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  z-index: 800;
}


.overlay-lock.scroll-lock{
  position: absolute;
  right: 0;
  left: 0;
  top:0;
  bottom: 0;
  width: 100%;
  height: 100%;
}


.overlay-lock.menu-active .page-content {
  transform: translateX(300px);

  .page-content-overlay {
    position: absolute;
    right: 0;
    left: 0;
    top:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: .1;
    z-index: 9000;
  }
}

.overlay-lock.menu-active .menu-toggle-btn {
  opacity: .6;
}

.overlay-lock.menu-active .left-nav {
  transform: translateX(0px);
}



.overlay-lock.loading-active {
  .panel-overlay {
    position: absolute;
    right: 0;
    left: 0;
    top:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
    opacity: 1;
    z-index: 99999;
  }

  .overlay-loader {
    color: #545454;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: inline-block;
    font-size: 72px;
  }
}

.overlay-lock.navigating-active {
  .panel-overlay {
    position: absolute;
    right: 0;
    left: 0;
    top:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #FFF;
    opacity: 1;
    z-index: 99999;
  }

  .loader-text {
    display: block;
    position: absolute;
    font-weight: bold;
    font-size: 27px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color:#873A8C;
  }
}


//Ui dialog

.ui-dialog	{
  top: 25vh;
  border: none;
  width: 100%;
  max-width: 600px;
  border-radius: 0;

  .form-subhead {
    font-weight: 300;
    color:$warn-red;
    padding-bottom: 15px;
  }

  .ui-dialog-titlebar {
    padding-bottom: 0;
  }

  .ui-widget-header {
    // background: $base-grey;
    color:$brand-purple;
    font-weight: 600;
    padding-bottom: 0;
  }
}

.ui-widget-overlay {
  background: rgba(0, 0, 0, 0.5);
  min-height: 800px;
}

.ui-dialog .ui-dialog-titlebar-icon,
.ui-dialog-titlebar-icon:hover {
  color: $light-text;
  margin-left: 15px;
  font-size: 20px;
}



.ui-dialog-titlebar-icon:hover {
  color: #000;
}



.remove-member-btn {
  float: right;
}

//terms overlay
.terms-overlay {
  border-radius: 10px;
  overflow: hidden;
  padding:20px;
  max-width: 800px!important;

  .ui-dialog-titlebar-close {
    display: none;
  }

  .ui-dialog-titlebar {
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 4px!important;
  }

  .ui-dialog-title {
    color: #282828;
    font-weight: 300;
  }

  .term-text {
    color:$dark-text;
  }
  .button-row {
    text-align: right;
  }
  .agree-term-form {
    padding-top: 10px;
    color:$dark-text;
    a {
      color:#3992D2;
    }
  }
}

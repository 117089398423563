.ui-datatable .ui-datatable-data>tr>td{
 padding:.7em;
 border: none;
 color:$dark-text;
 font-size:14px;
 padding:.7em;
 vertical-align:middle;
 font-family: $open-sans;
}

.ui-datatable .ui-datatable-data>tr>td.no-padding-cell{
 padding:0!important
}

.ui-datatable {
  -webkit-transform: translate3d(0, 0, 0);
  font-family: $open-sans;
  position: relative;
  z-index: 500;
}

.ui-datatable .ui-widget-header .ui-state-default {
  border:none;
}

.ui-datatable tbody > tr.ui-widget-content.ui-state-highlight {
  background: #FFFFFF;

  &:hover {
    cursor: pointer;
    background: #eeeeee;
    color: #212121;
  }
}

.ui-datatable .ui-sortable-column-icon {

  &:before{
    visibility:hidden;
    font-family: "eleoticons" !important;
    content: "\6a"!important;
    position: relative;
    top:3px;
  }

  &.fa-sort-asc:before {
    visibility:visible;
    font-family: "eleoticons" !important;
    content: "\6a"!important;
  }
  &.fa-sort-desc:before {
    visibility:visible;
    font-family: "eleoticons" !important;
    content: "\67"!important;
  }
}
.icon-col {
  width: 45px;
  overflow: visible!important;
}

.table-menu-wrap {
  position: relative;
  text-align: center;
  cursor: pointer;

  & > .fa {
    position: relative;
    top:3px;
    position: relative;
    z-index: 1;
    color:$light-text;
  }

  &:hover > .fa {
    color:$text-base;
  }
}

.ui-datatable .ui-datatable-load-status {
  display: none;
}

.table-menu {
  display: none;
  position: absolute;
  right:0;
  top:20px;
  z-index: 900;
  box-shadow: 0px 1px 3px rgba(0,0,0,.7);
  width: 100px;
  text-align: left;

  &.activity-menu {
    width: 180px;
    span {
      color: $dark-text;
    }
  }

  .ui-menuitem {
    font-size: 16px;
    vertical-align: baseline;
  }
  .ui-menuitem .ui-menuitem-link {
    background: #FFF;
    vertical-align: baseline;
    padding: 5px;
  }

  .ui-menuitem-icon,
  .ui-menuitem-text {
    font-size: 14px;
    vertical-align: baseline!important;
  }
}



.table-menu-wrap.open .table-menu {
  display: block;
}

.ui-datatable tbody > tr.ui-widget-content {
 border: none;
}


.ui-widget-header {
  background: #FFF;
}


.ui-datatable tbody  {
 border: none;
 border-top: 1px solid #e7e8ea;
}

.ui-datatable th.ui-state-default {
 background: #FFF;
 border: none;
 color:$blue-text;
}

.ui-datatable .ui-datatable-thead>tr>th {
 border: none;
 text-align: left;
 font-size: 16px;
 padding:.7em .6em;
 font-weight: 300;
}

.ui-datatable tbody > tr.ui-widget-content.ui-datatable-odd {
 background-color: #F7F7F7;
}

.dual-row-col {
 .inner-col-row + .inner-col-row{
   padding-top: 5px;
 }
}

.status-col {
  width: 100px;
}

.date-col {
  width: 150px;
}

.grid-boolean {
  opacity: 0;
  color:$active-green;
  font-size: 20px;
  position: relative;
  left:-16px;
  &.active {
    opacity: 1;
  }
}

.grid-link {
  &:hover{
    text-decoration: underline;
  }
}

.center-cell {
  text-align: center;
}

.ui-datatable-scrollable-wrapper {
  border: 1px solid #e7e8ea;
}

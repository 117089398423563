//== Workspace List Component ==//

.workspace-list-grid {
  max-width: 990px;

  .institution-row {
    font-weight: 500;
  }

  .parent-institution-row {
    font-style: italic;
  }
}

//== Workspace Detail Component ==//
.team-members-wrap {
  .ui-cell-data {
    font-size: 15px;
    padding:.10em;
    line-height: 25px;
  }

  .indent-cell .ui-cell-data{
    padding-left: 25px!important;
    display: block;
  }

  .select-checkbox-cell {
    width: 80px;

    .checkbox {
      vertical-align: -5px;
    }
  }

  thead {
    display: none;
  }

  .ui-datatable-emptymessage {
    padding-left: 24px!important;
  }
}



.users-grid {

  .certified-cell {
    width: 137px;
    font-family: "Open Sans", Arial, Helvetica, sans-serif ;
  }

  .status-cell {
    width: 90px;
    font-family: "Open Sans", Arial, Helvetica, sans-serif ;
  }

  .role-cell {
    width: 100px;
    font-family: "Open Sans", Arial, Helvetica, sans-serif ;
  }

  .name-cell {
    font-family: "Open Sans", Arial, Helvetica, sans-serif ;
  }
}

.ellipsis-cell {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.classic-style-grid {
  .ui-datatable th.ui-state-default,
  .ui-datatable th.ui-state-default:hover {
    padding: .65em .6em;
    background: #F7F7F7;
    color: #333;
  }
  .ui-datatable .ui-datatable-thead>tr>th {
    border: none;
    border-bottom: 1px solid #ddd;
  }


  .ui-datatable th.ui-state-active .ui-column-title {
    text-decoration: underline;
  }
}

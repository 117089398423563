%confirm-cancel-modal-shared {
  max-width: 420px;
  .confirm-body {
    line-height: 1.2;
    text-align: left;
    padding-bottom: 10px;
  }
  .add-warning {
    color: $warn-red;
    font-size: 29px;
    padding-bottom: 20px;
  }

  .warn-message {
    font-size: 20px;
    line-height: 1.2em;
    padding-bottom: 20px;
  }

  .display-msg {
    font-weight: 600;

    padding-bottom: 20px;
  }

  .ui-dialog-titlebar {
    padding: 0;
  }

  .ui-dialog-content {
    padding-top: 40px !important;
  }

  .ui-widget-header {
    background: $brand-purple;
    color: white;
    font-weight: 600;
    padding-bottom: 0 !important;
    height: 60px;
  }
}

.share-report-confirm-modal, .withdraw-report-confirm-modal {
  @extend %confirm-cancel-modal-shared;
}

.share-report-confirm-modal, .withdraw-report-confirm-modal {
  .ui-dialog-titlebar.ui-widget-header {
    padding: 0 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .confirm-modal-header {
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    i {
      padding-right: 8px;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;

    .confirm-button {
      margin-left: 5px;
    }

    .cancel-button {
      margin-right: 5px;
    }
  }
}

@import "./vars-mixins";

.resource-confirm-modal {
  .button + .button {
    margin-left: 20px;
  }
}

.resource-upload-tabs {
  .tab {
    position: relative;
    height: 40px;
    border: 2px solid #e7e8ea;
    z-index: 700;
    display: inline-block;
    color:#9B9B9B;
    font-weight: 600;
    border-bottom:none;
    cursor: pointer;
  }

  .tab-content {
    padding: 10px;
    display: inline-block;
    height: 42px;

  }

  .tab.active{
    border-color:#B4B4B4;
    border-bottom-color: #FFF;
    .tab-content {
      height: 40px;
      background: #FFF;
    }
  }

}

.resource-upload-modal {
  max-width: 800px;

  .upload-icon {
    padding-right: 5px;
  }

  .ui-fileupload-buttonbar {
    padding: 0;
  }

  .name-wrap {
    position: relative;

    .resource-name {
      display: inline-block;
      word-wrap: break-word;
      float: left;
      max-width: 501px;
    }
  }



  .attachment-list-item {
    .resource-name {
      overflow: hidden;
      display: inline-block;
      position: relative;
      text-overflow: ellipsis;
    }
    .resource-size {
      display: inline-flex;
      flex-direction: row;
      flex: 1 0 auto;
      margin-right: 10px;
    }
  }

  .tab-content-body {
    display: none;
    height:232px;
    &.active-content{
      display: block;
    }
  }

  .ui-fileupload-files {
    color: $dark-text;
    border-bottom: 1px solid #e7e8ea;
    padding-bottom: 30px;
    display: block;
    height: 86px;
    padding-top: 10px;
  }
  .ui-fileupload-buttonbar .ui-button,
  .ui-fileupload-buttonbar .ui-button:hover {
    position: absolute;
    z-index: 900;
    display: inline-block;
    font-size: 16px;
    padding: 8.5px 10px;
    font-weight: 600;
    border-radius: 3px;
    border: none;
    outline: 0;
    vertical-align: middle;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    line-height:1;
    color:#9B9B9B;
    top:247px;
    opacity: 1!important;
    .ui-button-icon-left {
      display: none;
    }
    .ui-button-text {
      padding: 0;
    }
  }

  .ui-fileupload-buttonbar .ui-fileupload-choose {
    background: #EEEEEE;
    right:30px;
    top: 46px !important;

    &:hover{
      background: #EEEEEE;
    }
  }


  .ui-fileupload-buttonbar button:nth-child(2){
    right:-7px;
    background: $brand-purple;
    color:#FFF;

    &:hover {
      background: $brand-purple;
      color:#FFF;
    }

    &:disabled{
      background: $light-text;
      cursor: not-allowed!important;
    }
  }

  .not-valid .ui-fileupload-buttonbar button:nth-child(2){
    display: none;
  }


  .ui-fileupload-buttonbar button:nth-child(3),
  .ui-fileupload-buttonbar button:nth-child(3):hover{
    display: none;
    right:30px;
    top: 38px !important;
    background: none;
    text-decoration: underline!important;
    color: $dark-text;
    font-weight: 300;
  }

  .has-file.valid-file .ui-fileupload-buttonbar button:nth-child(3){display: block;}

  .resource-upload-wrap {
    position: relative;
    margin-bottom: 50px;
    border: 2px solid #B4B4B4;
  }

  .ui-fileupload-buttonbar,
  .ui-dialog-content {
    border: none!important;
  }

  .ui-widget-header {
    border:none;
  }

  .mock-save-btn {
    position: absolute;
    right: 23px;
    bottom: 17px;
  }

  .upload-form {
    padding: 0px 40px 10px;

    label {
      font-weight: 600;
      font-size: 17px;
      color:#9B9B9B;
      padding-bottom: 10px;
    }
    input[type='text']{
      background: #EEEEEE;
    }
  }

  .link-form {
    padding-top: 30px;
  }

  .ui-fileupload-content {
    // margin-bottom: 30px;
    // height: 240px;
    border-radius: 0;
    padding: 40px;
    border: none;
    color:#9B9B9B;
    padding: 38px 40px 20px 40px;
  }

  .file-header {
    color: $dark-text;
    position: absolute;
    font-weight: 600;
    top: 14px;
  }

  .has-file .ui-fileupload-choose {display: none;}


  .resource-upload-icon {
    margin-bottom:-4px;
    margin-right: 5px;
    float: left;
  }


  .cancel-btn {
    position: absolute;
    right: 90px;
    top:305px;
  }

  .link-save-btn {
    position: absolute;
    right: -3px;
    bottom: -51px;
  }

  .drag-display {
    color:#9B9B9B;
    border-bottom: 2px solid #e7e8ea;
    padding-bottom: 30px;
    height: 86px;
    .drag-icon {
      font-size: 50px;
    }
    .drag-text {
      font-weight: 600;
      display: inline-block;
      position: relative;
      left:10px;
      top:-17px;
      font-size: 20px;
    }
  }
}

.resource-rename-modal {
  label {
    font-weight: 600;
    font-size: 17px;
    color:#9B9B9B;
    padding-bottom: 10px;
  }
  input[type='text']{
    background: #EEEEEE;
  }
}

.resource-confirm-modal {
  max-width: 500px;
  .confirm-body {
    text-align: center;
    padding-bottom: 10px;
  }
  .add-warning {
    color: $warn-red;
    font-size: 29px;
    padding-bottom: 20px;
  }

  .warn-message {
    font-size: 20px;
    line-height: 1.2em;
    padding-bottom: 20px;
  }

  .display-msg {
    font-weight: 600;
    font-size: 17px;
    //color:#9B9B9B;
    padding-bottom: 30px;
  }
}

%resource-confirm-modal-shared {
  max-width: 420px;
  .confirm-body {
    line-height: 1.2;
    text-align: left;
    padding-bottom: 10px;
  }
  .add-warning {
    color: $warn-red;
    font-size: 29px;
    padding-bottom: 20px;
  }

  .warn-message {
    font-size: 20px;
    line-height: 1.2em;
    padding-bottom: 20px;
  }

  .display-msg {
    font-weight: 600;

    padding-bottom: 20px;
  }

  .ui-dialog-titlebar {
    padding: 0;
  }

  .ui-dialog-content {
    padding-top: 40px !important;
  }

  .ui-widget-header {
    background: $brand-purple;
    color: white;
    font-weight: 600;
    padding-bottom: 0 !important;
    height: 60px;
  }
}

.resource-confirm-modal-archive {
  @extend %resource-confirm-modal-shared;
}

.resource-confirm-modal-download-all {
  @extend %resource-confirm-modal-shared;
}

.resource-confirm-modal-download-all-error {
    @extend %resource-confirm-modal-shared;
    .ui-widget-header {
      background: $modal-error;
    }
}

.resource-confirm-modal-delete {
  @extend %resource-confirm-modal-shared;
  .ui-widget-header {
    background: $modal-error;
  }
}

$base-grey:rgba(238,240,243,1);
$brand-purple:#873A8C;
$dark-text:#565656;
$calendar-input-border: #d6d6d6;
$not-found-icon-font-size: 5rem; // 80px
$not-found-icon-opacity: .2;
$light-text:#979797;
$dark-button-background: #565656;
$tab-nav-bottom-border-color: #979797;
$divider-grey:#DDDDDD;
$active-green:#32BE67;
$action-plan-accepted-green: #248749;
$active-blue: #3793D2;
$warn-red:#F36A54;
$text-base:#334152;
$blue-text:#223E5E;
$accent-blue:#223E5E;
$accent-grey: #F7F7F7;
$disabled-grey:#D2D2D2;
$button-alt:#F5F5F5;
$border-grey: #CCCCCC;
$border-light-grey: #EBEBEB;
$background-light-grey: #F0F0F0;
$teamMembertraining:#217A41;
$earlyLearningTM:#2440AF;
$basic:#217A41;
$diagnosticreview:#227A82;
$stem:#7A5100;
$earlyLearning:#2440AF;
$white:#FFFFFF;
$leadEvaluatorStem:#637221;
$corporation:#CC4D24;
$eleot-certified: #822877;
$modal-error: #D54230;
$standards: #E28A1E;
$readiness: #B040CE;
$earlylearningStem: #DE1397;
$K12Stem: #0D365D;
$systems: #4A2A68;
$monitoring-review: #989E22;
$stem-recertification: #6330E5;
$main-brand-color: #ff564d;

$bold: 700;
$normal: 400;
$semi-bold: 600;

$h1-font-size: 1.875rem; // 30px
$h2-font-size: 1.5rem; // 24px
$h3-font-size: 1.125rem; // 18px

$popover-box-shadow: -0.313rem 0.625rem 1.875rem -0.563rem rgba(0, 0, 0, 0.75);  // -5px 10px 30px -9px

$open-sans: "Open Sans", Arial, Helvetica, sans-serif;
$carto: "CartoGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;

// GLOBAL MODAL VARIABLES ##################################################################################
// #############################################################################################
// FONTS
$modal-font-color: $light-text;
// BORDERS
$modal-border-color: #CCCCCC;
$modal-border-radius: .188rem; // 3px;
// MARGINS
$modal-body-content-margin-bottom: 2.5rem; // 40px;
$modal-instructions-margin-bottom: 1.563rem; // 25px;
// PADDING
$modal-title-bar-padding: 1.5rem 1rem;
$modal-body-padding: 2.5rem 2.5rem 0rem 2.5rem; // 40px 40px 0px 40px
$modal-body-content-padding: 2.5rem; // 40px
// BACKGROUNDS
$modal-input-background-color: #F8F8F8;

@mixin flex-center-x-y() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-in-view() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin placeholders {
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
     @content
  }
  ::-moz-placeholder {
     @content
  }
  :-ms-input-placeholder {
     @content
  }
  :-moz-placeholder {
     @content
  }
}


@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin default-grid () {
  color: $dark-text;
  th {
    padding: 0.625rem;
  }
  td {
    padding: 0.625rem;
    vertical-align: middle;
  }
  .expand-icon {
    cursor: pointer;
  }
  .grid-header-row {
    background: #f7f7f7;
    color: #333;
  }

  .col-header {
    text-align: left;
    &:nth-child(5) {
      width: 5.875rem; // 94px;
    }
  }
  .col-header-spacer {
    width: 3rem;
  }
  .grid-row {
    border-bottom: 1px solid #DDD;
  }
  .table-container {
    background: #f6f6f6;
    color: $dark-text;
    padding: 1rem;
  }

  .expanded-table {
    th {
      border-bottom: 1px solid gray;
      padding-bottom: 0.5rem;
    }
    td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    a {
      text-decoration: underline;
      color: $dark-text;
    }
  }
  .expanded-col-header {
    text-align: left;
  }
  .center-text {
    text-align: center;
  }
}

@mixin word-wrap-with-hyphens () {
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

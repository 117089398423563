@import "./vars-mixins";
#assign-notifications-component {
  background: $accent-grey;
  padding: 25px;
  h1 {
    margin-bottom: 15px;
    font-weight: bold;
    color: $dark-text;
  }
  .instructional-text {
    margin-bottom: 15px;
  }
  .flex-container {
    display: flex;
  }
  .chips-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  #side-panel-multi-select {
    width: 630px;
    margin-right: 10px;
    .ui-widget-header {
      display: none;
    }
    .ui-multiselect-item {
      display: flex;
      align-items: center;
      &:nth-child(even){
        background: $background-light-grey;
      }
      * {
        align-items: center;
      }
    }
  }

  .multi-select-item-container {
    display: inline-flex;
    justify-content: space-between;
    width: calc(100% - 60px);
    height: 36px;
  }

  .team-member-name {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .team-member-label {
    font-style: italic;
  }

  .button {
    height: 36px;
    width: 100px;
    padding: 0px;
  }

  .my-chip {
    cursor: pointer;
    height: 36px;
    min-width: 150px;
    max-width: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-right: 6px;
    background: white;
    color: black;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 4px;
    &:hover {
      background: $background-light-grey;
    }

    .chip-label {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      display: inline-block;
    }
    .chip-close {

    }
  }
}

//overrides for the prime ng tooltip component

.ui-tooltip {

  .ui-tooltip-text {
    background: $dark-text;
    font-size: 14px;
    padding: 6px 10px;
  }

  .ui-tooltip-arrow {
    border-top-color: $dark-text;
  }
}

/*.workspaceActivity {
  .activityTabButton {
    //background-color: #ffffff !important;
    //top: -15px !important;
  }

  margin: 0 auto;
  max-width: 999px;
}*/

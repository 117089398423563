#resources-view {
  .ui-treetable-data.ui-widget-content{
    border: none!important;
  }

  .pi-chevron-right {
    &:before {
      content: "\e905"; // Change chevron to a caret to match current style. Latest PrimeNG changed icon to a chevron.
      color: #565656;
    }
  }

  .pi-chevron-down {
    &:before {
      content: '\e906'; // Change chevron to a caret to match current style. Latest PrimeNG changed icon to a chevron.
      color: #565656;
    }
  }

  .resource-icon-img{
    height: 19.2969px;
    width: 19.2969px;
    display: inline-block;
    margin-bottom: -4px;
    margin-right: 4px;
  }

  .resource-table{
    border: none;

    table.ui-widget-content {
      border: none;
      table-layout: fixed;
    }

    th.ui-state-default{
      background: #FFF;
      text-align: left;
      border: none;
      padding:10px;
      border-bottom: 1px solid $divider-grey;
      &.ellipsis-cell {
        padding-left: 36px;
      }

    }

    .grid-header-row {
      th {
        background: #f7f7f7;
        color: #333;
      }
    }

    thead tr.ui-state-default{
      border: none;
    }

    .inactive {
      opacity: .46;
    }

    .lock-icon {
      padding-right: 5px;
    }

    .ui-treetable-toggler {
      vertical-align:baseline;
    }

    .ui-treetable-row {
      border-bottom: 1px solid $divider-grey;
      font-size: 18px;
    }

    tbody td {
      padding:10px 10px 9px 10px;
      color: #565656;
    }

    .highlight-text {
      vertical-align: baseline!important;
    }

    tbody td:first-child span {
      vertical-align: baseline;
    }

    .ui-treetable-child-table-container {
      overflow: visible;
    }



  }

  .size-col {
    width: 110px;
  }
  .added-col {
    width: 130px;
  }
  .resource-icon-col {
    width: 50px;
  }

  .icon-col {
    width: 110px;
    .resource-menu-element {
      padding-right: 12px;
    }
  }

  .created-col {
    width: 170px;
    white-space: normal !important;
    overflow: unset;
    word-wrap: break-word;
  }


  .resource-icon {
    position: relative;
    margin-bottom:-1px;
    margin-right: 5px;
  }


  .search-file-path {
    padding-top: 10px;
    color: $light-text;
    font-size: 12px;
  }
}


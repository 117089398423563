@import './vars-mixins';
.activity-page-tooltip {
    color: #FFFFFF;
    font-family: $open-sans;
    font-weight: 400;
    letter-spacing: 0.04rem; // 0.64px
    line-height: 1.1875rem; // 19px;
    min-width: 19.6875rem; // 315px;
    text-align: left;
    .ui-tooltip-text {
        font-size: 1rem;
    }
  }
